// https://github.com/getsentry/sentry/blob/42eda359bf9b18a02ff5dccea9763d846cda780c/src/sentry/static/sentry/app/utils/theme.tsx
import { get } from "lodash-es";
import { ThemeProvider } from "styled-components";

import colors from "common/config/colors.json";

const spaces = {
    "0": "0",
    "0.25": "2px",
    "0.5": "4px",
    "0.75": "6px",
    "1": "8px",
    "1.5": "12px",
    "2": "16px",
    "3": "20px",
    "4": "30px"
};

const fontSizes = {
    "0": "36px", // font-size: ${({ theme }) => theme.headerFontSizeLarge};
    "1": "27px", // font-size: ${({ theme }) => theme.headerFontSizeMedium};
    "2": "20px", // font-size: ${({ theme }) => theme.headerFontSize};
    "3": "16px", // font-size: ${({ theme }) => theme.fontSizeExtraLarge};
    "4": "14px", // font-size: ${({ theme }) => theme.fontSizeLarge};
    "5": "12px", // font-size: ${({ theme }) => theme.fontSizeMedium};
    "6": "10px", // font-size: ${({ theme }) => theme.fontSizeSmall};
    "7": "8px",
    get h0() { return this["0"]; },
    get h1() { return this["1"]; },
    get h2() { return this["2"]; },
    get h3() { return this["3"]; },
    get h4() { return this["4"]; },
    get h5() { return this["5"]; },
    get h6() { return this["6"]; },
    get h7() { return this["7"]; },
    get h8() { return this["8"]; }
};

const fontStyles = {
    regular: "\"nunito_sans_regular\", sans-serif", // font-family: ${({ theme }) => theme.text.regular};
    medium: "\"nunito_sans_semibold\", sans-serif", // font-family: ${({ theme }) => theme.text.medium};
    bold: "\"nunito_sans_bold\", sans-serif", // font-family: ${({ theme }) => theme.text.bold};
    light: "\"nunito_sans_light\", sans-serif" // font-family: ${({ theme }) => theme.text.light};
};

const analyticsSectionColors = {
    authorizations: get(colors, "blue-light"),
    fees: get(colors, "orange-mid"),
    settlements: get(colors, "aqua"),
    sales: get(colors, "aqua"),
    refunds: get(colors, "blue-mid"),
    refundRate: get(colors, "blue-mid"),
    netSettledCash: get(colors, "blue-mid"),
    averageTicket: get(colors, "orange-mid"),
    chargebacks: get(colors, "purple-mid"),
    cashflow: get(colors, "green-mid"),
    misc: get(colors, "key-lime"),
    psd2_fraud: get(colors, "brick"),
    all_fraud: get(colors, "brick"),
    threedsflex: get(colors, "green-dark"),
    fraudsight: get(colors, "green-dark")
};

export const theme = {
    analyticsSectionColor: (section) => get(analyticsSectionColors, section),
    font: (style, size) => `font-family: ${fontStyles[style]};\nfont-size: ${fontSizes[size]};`, // Backwards compatibility with `@include font(style, size)` rule
    space: (size) => spaces[size],
    size: (size) => fontSizes[size], // Backwards compatibility with current typography system (h0..h7)
    
    fontSizeExtraSmall: "8px",
    fontSizeSmall: "10px",
    fontSizeMedium: "12px",
    fontSizeLarge: "14px",
    fontSizeExtraLarge: "16px",
    headerFontSize: "20px",
    headerFontSizeMedium: "27px",
    headerFontSizeLarge: "36px",
    text: { ...fontStyles },

    borderRadius: "2px",

    colors,
    textColor: colors["gray-black"]
};

export const withTheme = (Component, selectedTheme = theme) => <ThemeProvider theme={selectedTheme}>{Component}</ThemeProvider>;
