import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    persist_settings: false,
    viewInspector: false,
    polling: true,
    overrideAPI: "default",
    create_sso_users: false,
    run_sentry_localhost: false,
    dashboardLazyLoad: true,
    showAnalyticsReact: false,
    useTestApiHeader: false,
    showSearchReact: false
};

const reducers = {
    PERSIST_SETTINGS:
        (state, action) => ({ ...state, persist_settings: action.payload }),

    DEBUG_MODE:
        (state, action) => ({ ...state, viewInspector: action.payload }),
    
    POLLING:
        (state, action) => ({ ...state, polling: action.payload }),
    
    SET_API:
        (state, action) => ({ ...state, overrideAPI: action.payload }),
    
    CREATE_SSO_USERS:
        (state, action) => ({ ...state, create_sso_users: action.payload }),
    
    RUN_SENTRY_LOCALHOST:
        (state, action) => ({ ...state, run_sentry_localhost: action.payload }),
    
    SYNC_STATE: (state, { payload: newState }) => ({
        ...state,
        ...newState
    }),

    RESET_STATE: () => ({ ...initialState }),

    SET_DASHBOARD_LAZY_LOAD:
        (state, action) => ({ ...state, dashboardLazyLoad: action.payload }),
        
    SET_SHOW_ANALYTICS_REACT:
        (state, action) => ({ ...state, showAnalyticsReact: action.payload }),

    SET_TEST_API_HEADER:
    (state, action) => ({ ...state, useTestApiHeader: action.payload }),

    SET_SHOW_SEARCH_REACT:
        (state, action) => ({ ...state, showSearchReact: action.payload })
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers
});

export const settings = settingsSlice.reducer;
export const {
    PERSIST_SETTINGS,
    DEBUG_MODE,
    POLLING,
    SET_API,
    CREATE_SSO_USERS,
    RUN_SENTRY_LOCALHOST,
    SYNC_STATE,
    RESET_STATE,
    SET_DASHBOARD_LAZY_LOAD,
    SET_SHOW_SDR_FEATURE,
    SET_SHOW_ANALYTICS_REACT,
    SET_TEST_API_HEADER,
    SET_SHOW_SEARCH_REACT
} = settingsSlice.actions;
